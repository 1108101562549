import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LinkNotFoundComponent } from './common/link-not-found/link-not-found.component';

const routes: Routes = [
  { path: 'about', loadChildren: () => import('./modules/about/about.module').then(m =>m.AboutModule) },
  { path: 'contact', loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule) },
  { path: 'experience', loadChildren: () => import('./modules/experience/experience.module').then(m => m.ExperienceModule) },
  { path: 'skills', loadChildren: () => import('./modules/skills/skills.module').then(m => m.SkillsModule) },
  { path: 'home', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: '', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', component: LinkNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { trigger, transition, animate, state, style, query, group, animateChild } from '@angular/animations';

const forwardAnimation = [
  style({
    position: 'relative',
    height: '!'
  }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%'
    })
  ]),
  //
  query(':enter', [
    style({ transform: 'translateY(100%)' })
  ]),
  query(':leave', animateChild()),
  group([
    query(':leave', [
      animate('500ms cubic-bezier(.92,0,.32,.92)', style({ transform: 'translateY(-100%)' }))
    ]),
    query(':enter', [
      animate('500ms cubic-bezier(.92,0,.32,.92)', style({ transform: 'translateY(0%)' }))
    ])
  ]),
  query(':enter', animateChild()),
];
const reverseAnimation = [
  style({ position: 'relative', height: '!' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%'
    })
  ]),
  //
  query(':enter', [
    style({ transform: 'translateY(-100%)' })
  ]),
  query(':leave', animateChild()),
  group([
    query(':leave', [
      animate('500ms cubic-bezier(.92,0,.32,.92)', style({ transform: 'translateY(100%)' }))
    ]),
    query(':enter', [
      animate('500ms cubic-bezier(.92,0,.32,.92)', style({ transform: 'translateY(0%)' }))
    ])
  ]),
  query(':enter', animateChild()),
];

export let  routerTransition =
   trigger('routerTransition',
   [
//from Home
    transition('HomePage => AboutPage', forwardAnimation),
    transition('HomePage => ContactPage', forwardAnimation),
    transition('HomePage => ExperiencePage', forwardAnimation),
    transition('HomePage => SkillsPage', forwardAnimation),
//About page
    transition('AboutPage => HomePage', reverseAnimation),
    transition('AboutPage => ContactPage', forwardAnimation),
    transition('AboutPage => ExperiencePage', forwardAnimation),
    transition('AboutPage => SkillsPage', forwardAnimation),
//skills page
transition('SkillsPage => HomePage', reverseAnimation),
transition('SkillsPage => AboutPage', reverseAnimation),
transition('SkillsPage => ExperiencePage', forwardAnimation),
transition('SkillsPage => ContactPage', forwardAnimation),
//experience page
transition('ExperiencePage => HomePage', reverseAnimation),
transition('ExperiencePage => AboutPage', reverseAnimation),
transition('ExperiencePage => SkillsPage', reverseAnimation),
transition('ExperiencePage => ContactPage', forwardAnimation),
//contact page
transition('ContactPage => HomePage', reverseAnimation),
transition('ContactPage => AboutPage', reverseAnimation),
transition('ContactPage => SkillsPage', reverseAnimation),
transition('ContactPage => ExperiencePage', reverseAnimation)

  ]
  )
;


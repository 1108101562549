<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <!-- <div class="container-fluid"> -->
    <div class="navbar-header d-flex flex-grow-1">
        <span class="w-100 d-lg-none d-block"><!-- hidden spacer to center brand on mobile --></span>
      <a class="navbar-brand" href="#">Sarath Madhu</a>
    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" (click)="isCollapsed = !isCollapsed"
      data-target="#navbarNav" aria-controls="navbarNav" [attr.aria-expanded]="!isCollapsed"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse flex-grow-1 text-right" id="navbarNav" [collapse]="isCollapsed">
      <ul class="navbar-nav ml-auto flex-nowrap">
        <li routerLinkActive="active current">
          <a class="nav-link" routerLink="home" >Home</a>
        </li>
        <li class="nav-item" routerLinkActive="active current">
          <a class="nav-link" routerLink="about">About</a>
        </li>
        <li class="nav-item" routerLinkActive="active current">
          <a class="nav-link" routerLink="skills">Skills</a>
        </li>
        <li class="nav-item" routerLinkActive="active current">
          <a class="nav-link" routerLink="experience">Experience</a>
        </li>
        <li class="nav-item" routerLinkActive="active current">
          <a class="nav-link" routerLink="contact">Contact</a>
        </li>
        <!-- <li class="nav-item">
        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
      </li> -->
      </ul>
    </div>

  <!-- </div> -->
</nav>
